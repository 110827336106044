import { BaseDotsamaWallet } from '../base-dotsama-wallet';
import logo from "./azerosigner.svg";

export class AzeroSignerWallet extends BaseDotsamaWallet {
  extensionName = "aleph-zero-signer";
  title = "Aleph Zero Signer";
  installUrl =
    "https://chrome.google.com/webstore/detail/aleph-zero-signer/opbinaebpmphpefcimknblieddamhmol";
  noExtensionMessage =
    "You can use any Polkadot compatible wallet but we recommend using Subwallet or Aleph Zero Signer";
  logo = {
    src: logo,
    alt: "Aleph Zero Signer Logo",
  };
}
