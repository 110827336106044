import "antd/dist/antd.css";
import "./AccountList.scss";

import { ApiPromise, WsProvider } from "@polkadot/api";
import {
    Button,
    Modal,
    Progress,
    Space,
    Spin,
    Table,
    Tabs,
    message,
} from "antd";
import { OpenSelectWallet, WalletContext } from "../contexts";
import React, { useContext, useEffect, useState } from "react";

import BN from "bn.js";
import { ContractPromise } from "@polkadot/api-contract";
import { EditOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import abi from "../contract/metadata.json";

// import ViewPunks from "./ViewPunks";

// import ModifiedTable from "./ModifiedTable";

const { TabPane } = Tabs;

// Staking table
const columns = [
    {
        title: "Silver Earned",
        dataIndex: "silverCount",
    },
    {
        title: "Address",
        dataIndex: "address",
    },
    {
        title: "Created Date",
        dataIndex: "createdDate",
    },
    {
        title: "Last Stake Date",
        dataIndex: "lastStakeDate",
    },
    {
        title: "Last Auto Stake Date",
        dataIndex: "lastAutoStakeDate",
    },
];
// Staking table ends

const wsUrl = "wss://ws.azero.dev";
const metadataUrl =
    "https://ipfs.mint.azeropunks.com/ipfs/QmUZmchEomSc7Jh5rVHYrZcKahSyn7t5zs5MR2fgKaHGq4/"; //+1.json
const imageUrl =
    "https://ipfs.mint.azeropunks.com/ipfs/QmT57pA1rX9PgN93W1jeqmGR4MQnoprF8oco8MdTC77qwH/"; //+1.png
const contractAddress = "5E7HFbvv7o4zFpJxnUsJF5CNktew1T6nh3gctbkSTWGAeiMT";
let mintAllowed = false;
const totalPunks = 10000;
// @ts-ignore
const mintPrice = 35n * 1000000000000n;
const punksSalePriceText = 35;
// let remainingAvailable = "1000";

interface Props {
    className?: string;
}

function AccountList({}: Props): React.ReactElement<Props> {
    const walletContext = useContext(WalletContext);
    const selectWallet = useContext(OpenSelectWallet);

    //Modal Handler
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // SET REACTIVE STATES
    let [isSuccess, setIsSuccess] = useState(false);
    let [isProcessing, setIsProcessing] = useState(false);
    let [punksSalePrice, setPunksSalePrice] = useState(0);
    let [userBalance, setUserBalance] = useState(0);
    let [soldPunks, setsoldPunks] = useState(0);
    let [userPunkCount, setUserPunkCount] = useState(0);
    let [maxLimit, setMaxLimit] = useState(10);
    let [allowMint, setAllowMint] = useState(false);
    let [count, setCount] = useState(1);
    let [punkMinted, setPunkMinted] = useState(0);
    let [userPunks, setUserPunks] = useState([]);
    const [imageArray, setImageArray] = useState<any[]>([]);
    let [selectedImage, setSelectedImage] = useState<string>("");
    const [selectedAddress, setSelectedAddress] = useState<string>("");
    const [stakeCount, setStakeCount] = useState(0);
    const [enableTable, setEnableTable] = useState(false);
    const [loading, setLoading] = useState(false);

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 48, color: "#01b69b" }} spin />
    );

    // END OF REACTIVE STATES

    interface StakeData {
        key: string;
        token?: string; // Mark as optional if this field might not exist
        stakeCount: number;
        silverCount: string;
        address: string;
        createdDate: string;
        lastStakeDate: string;
        lastAutoStakeDate: string;
    }

    const [data, setData] = useState<StakeData[]>([]);
    // const [data, setData] = useState<StakeData[]>([
    //     {
    //         key: "keyString",
    //         stakeCount: 4,
    //         silverCount: 4 + " silver",
    //         address: "lddkfjsdklfjllddkfjsdklfjllddkfjsdklfjl",
    //         createdDate: "today, tomorrow ?",
    //         lastStakeDate: "6 days ago",
    //         lastAutoStakeDate: "tomorrow",
    //     },
    // ]);

    //Set Reactive State for Attributes
    let [attrName, setAttrName] = useState("");
    let [attrDesc, setAttrDesc] = useState("");
    let [attr, setAttr] = useState<any[]>([]);

    //Get dropdown value
    const getInitialState = () => {
        const value = 1;
        return value;
    };

    const [value, setValue] = useState(getInitialState);

    const handleChange = (value: number) => {
        setValue(value);
    };

    let punkText = value > 1 ? " Punks " : " Punk ";
    // let calculateTotal = value + punkText + 'Total: ' + value * punksSalePriceText;
    let calculateTotal = "Total: " + value * punksSalePriceText;
    let totalPrice = value * punksSalePriceText;

    //Get Balance
    const getBalance = async (selectedAddress: string) => {
        const wsProvider = new WsProvider(wsUrl);
        const api = await ApiPromise.create({
            provider: wsProvider,
            noInitWarn: true,
        });

        let account = await api.query.system.account(selectedAddress);

        // @TODSO: Fix this dirty stuff for 'properly' showing the AZERO balance value
        const trillion = BigInt(1000000000000);
        // @ts-ignore
        setUserBalance((account.data.free.toBigInt() / trillion).toString());
        // api.rpc.chain.subscribeNewHeads((header) => {
        //   console.log(`Chain is at #${header.number}`);
        // });
    };

    //Get Sale Data
    const getSaleData = async () => {
        const wsProvider = new WsProvider(wsUrl);
        const api = await ApiPromise.create({
            provider: wsProvider,
            noInitWarn: true,
        });

        // NOTE the apps UI specified these in mega units -> https://polkadot.js.org/docs/api-contract/start/contract.read
        // @ts-ignore
        //const gasLimit = 3000n * 1000000n;
        // Read from the contract via an RPC call
        const value = 0; // only useful on isPayable messages -> https://polkadot.js.org/docs/api-contract/start/contract.read
        const storageDepositLimit = null;

        const gasLimit: any = api.registry.createType("WeightV2", {
            refTime: new BN("10000000000"),
            proofSize: new BN("10000000000"),
        });

        // @ts-ignore
        const contract = new ContractPromise(api, abi, contractAddress);
        const { gasRequired, storageDeposit, result, output } =
            await contract.query.getMaxMint(contractAddress, {
                gasLimit: gasLimit,
                storageDepositLimit,
            });
        // console.log(JSON.stringify(result.toHuman()));
        if (result.isOk) {
            // should output 123 as per our initial set (output here is an i32)
            // @ts-ignore
            setMaxLimit(output.toJSON()["ok"]);
            // console.log('Max mint:', output.toHuman());
        } else {
            console.error("Error", result.asErr);
        }

        const resultAllowMint = await contract.query.getAllowMint(
            contractAddress,
            { value: 0, gasLimit: gasLimit }
        );
        if (resultAllowMint.result.isOk) {
            // should output 123 as per our initial set (output here is an i32)
            // @ts-ignore
            setAllowMint(resultAllowMint.output.toJSON()["ok"]);
            //setAllowMint(true);
            // console.log("Allow mint:", resultAllowMint.output!.toHuman());
        } else {
            console.log("Don't Allow mint:", resultAllowMint.output!.toHuman());
        }

        const resultGetPrice = await contract.query.getPrice(contractAddress, {
            value: 0,
            gasLimit: gasLimit,
        });
        if (resultGetPrice.result.isOk) {
            // should output 123 as per our initial set (output here is an i32)
            // @ts-ignore
            setPunksSalePrice(resultGetPrice.output.toHuman());
        } else {
            console.error("Error", resultGetPrice.result.asErr);
        }

        const resultCurrentSupply = await contract.query["psp34::totalSupply"](
            contractAddress,
            { value: 0, gasLimit: gasLimit }
        );
        if (resultCurrentSupply.result.isOk) {
            // should output 123 as per our initial set (output here is an i32)
            // @ts-ignore
            setsoldPunks(
                // @ts-ignore
                resultCurrentSupply.output
                    .toJSON()
                    ["ok"].toString()
                    .replace(",", "")
                    .replace(".", "")
            );
            // @ts-ignore
            // setsoldPunks(10000);

            // @ts-ignore
            // console.log("Number minted:", resultCurrentSupply.output.toHuman());
        } else {
            console.error("Error", resultCurrentSupply.result.asErr);
        }
    };

    // This useEffect is triggered whenever the walletContext or punkMinted state variables are changed.
    // This does trigger a few times too many due to the the walletContext dependency.
    // So far the cleanest way I (Jeroen) could find. Feel free to find a better approach
    useEffect(() => {
        let isMounted = true;
        const init = async () => {
            await getSaleData();
            const defaultAddress =
                walletContext &&
                walletContext.accounts &&
                walletContext.accounts[0] &&
                walletContext.accounts[0].address;

            if (isMounted) {
                updateAddress(defaultAddress);
                await getBalance(defaultAddress);
            }
        };

        init().catch(console.error);

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletContext, punkMinted]);

    const mint = async (count: number, address: string) => {
        const wsProvider = new WsProvider(wsUrl);
        const api = await ApiPromise.create({
            provider: wsProvider,
            noInitWarn: true,
        });

        // NOTE the apps UI specified these in mega units -> https://polkadot.js.org/docs/api-contract/start/contract.read
        // @ts-ignore
        //const gasLimit = 8000n * 1000000n;
        // Read from the contract via an RPC call
        //TODO / Hardcoded for now, it's fine Use count * Price (retreived from contract state) for this value
        const value = BigInt(count) * mintPrice; // only useful on isPayable messages -> https://polkadot.js.org/docs/api-contract/start/contract.read

        // @ts-ignore
        const contract = new ContractPromise(api, abi, contractAddress);

        // @ts-ignore
        const injectedSigner: Signer = walletContext.wallet?.signer;

        const gasLimit: any = api.registry.createType("WeightV2", {
            refTime: new BN("20000000000"),
            proofSize: new BN("20000000000"),
        });

        const options = {
            gasLimit: gasLimit,
            storageDepositLimit: null,
            value: value,
        };

        const { gasRequired } = await contract.query.mintPub(
            address,
            options,
            count
        );

        const optionsReal = {
            gasLimit: gasRequired,
            storageDepositLimit: null,
            value: value,
        };

        // @ts-ignore
        await contract.tx
            .mintPub(optionsReal, count)
            .signAndSend(address, { signer: injectedSigner }, (result) => {
                if (result.status.isInBlock) {
                    setIsProcessing(true);
                    setIsSuccess(false);
                } else if (result.status.isFinalized) {
                    if (result.isError) {
                        message.success(
                            "Something went wrong. Please try again."
                        );
                    } else {
                        setIsProcessing(false);
                        setTimeout(() => {
                            setIsSuccess(false);
                        }, 3000);
                        setIsSuccess(true);
                        getPunks(address);
                        setPunkMinted(punkMinted + 1);
                    }
                }
            });
    };

    const getPunks = async (address: string) => {
        // console.log("Getting punks for address: " + address);
        const wsProvider = new WsProvider(wsUrl);
        const api = await ApiPromise.create({
            provider: wsProvider,
            noInitWarn: true,
        });

        // NOTE the apps UI specified these in mega units -> https://polkadot.js.org/docs/api-contract/start/contract.read
        // @ts-ignore
        const value = 0; // only useful on isPayable messages -> https://polkadot.js.org/docs/api-contract/start/contract.read

        const gasLimit: any = api.registry.createType("WeightV2", {
            refTime: new BN("10000000000"),
            proofSize: new BN("10000000000"),
        });

        // @ts-ignore
        const contract = new ContractPromise(api, abi, contractAddress);

        const { gasConsumed, result, output } = await contract.query[
            "psp34::balanceOf"
        ](address, { value: 0, gasLimit: gasLimit }, address);

        // console.log(JSON.stringify(result.toHuman()));
        if (result.isOk) {
            // should output 123 as per our initial set (output here is an i32)
            // @ts-ignore
            setUserPunkCount(output.toJSON()["ok"]);

            // @ts-ignore
            // console.log("Number of punks you own:", output.toHuman());

            // @ts-ignore

            //v2
            const promises = Array.from({ length: output.toJSON()["ok"] }).map(
                async (_, i) => {
                    const punkIndexResult = await contract.query[
                        "psp34Enumerable::ownersTokenByIndex"
                    ](address, { value: 0, gasLimit: gasLimit }, address, i);
                    if (
                        punkIndexResult.result.isOk &&
                        punkIndexResult.output != null
                    ) {
                        // @ts-ignore
                        const pNumber: any = punkIndexResult?.output
                            ?.toJSON()
                            ["ok"]["ok"]["u64"].toString()
                            .replace(",", "")
                            .replace(".", "");

                        let NFTName;
                        let image = imageUrl + pNumber + ".png";

                        const metadata = metadataUrl + pNumber + ".json";

                        try {
                            const response = await fetch(
                                metadataUrl + `${pNumber}.json`
                            );
                            const data = await response.json();
                            NFTName = data.name;
                            image = generateUrlFromImageMetadata(data.image);
                        } catch (error) {
                            console.error(error);
                        }
                        return [pNumber, image, NFTName];
                    }
                }
            );

            const promiseArray = await Promise.all(promises);
            // console.table("promiseArray = " + promiseArray);
            if (promiseArray.length >= 1) {
                setImageArray(promiseArray);
                const imageWrapper = document.getElementById("nftImageWrapper");
                imageWrapper!.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                });
            } else {
                setImageArray([]);
            }
        } else {
            console.error("Error", result.asErr);
        }
    };

    const signDummy = async (address: string) => {
        const signer = walletContext.wallet?.signer;
        if (signer && signer.signRaw) {
            const signPromise = signer.signRaw({
                address,
                data: "This is dummy message",
                type: "bytes",
            });
            const key = "sign-status";
            message.loading({ content: "Signing", key });
            signPromise
                .then((rs: any) => {
                    message.success({ content: "Sign Successfully!", key });
                })
                .catch((error) => {
                    message.warn({ content: "Sign Failed or Cancelled!", key });
                });
        }
    };

    function increment() {
        setValue(function (prevValue) {
            if (prevValue < 10) {
                return (prevValue += 1);
            } else {
                return (prevValue = 10);
            }
        });
    }

    function decrement() {
        // console.log();
        setValue(function (prevValue) {
            if (prevValue > 0 && prevValue <= 10) {
                return (prevValue -= 1);
            } else {
                return (prevValue = 1);
            }
        });
    }

    function postStakeData(selectedAddress: string) {
        const url = `https://azeropunks-staking.azurewebsites.net/Staking/stake?address=${selectedAddress}`;
        setLoading(true);

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    Modal.info({
                        title: "Information",
                        content: "No new NFTs staked",
                    });
                    return null; // no content to parse
                }
                if (!response.ok) {
                    Modal.error({
                        title: "Error",
                        content: `Network response: ${response.status} ${response.statusText}`,
                    });
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data: any) => {
                if (!data || (data && Object.keys(data).length === 0)) {
                    Modal.info({
                        title: "Information",
                        content: "No new NFTs staked",
                    });
                } else {
                    Modal.success({
                        title: "Success",
                        content: "NFTs staked successfully",
                    });
                }

                getStakeData(selectedAddress);
            })
            .catch((error) => {
                Modal.error({
                    title: "Something went wrong",
                    content:
                        "We encountered an issue while processing your request. Please try again later. If the issue persists, contact us.",
                });
            })
            .finally(() => setLoading(false));
    }

    function getStakeData(selectedAddress: string) {
        const url = `https://azeropunks-staking.azurewebsites.net/Staking/balance?address=${selectedAddress}`;
        setLoading(true);

        function formatDateString(dateStr: string): string {
            const dateObj = new Date(dateStr);
            return dateObj.toLocaleString(); // Use your preferred formatting here
        }

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((fetchedData: any) => {
                setData([
                    {
                        key: "1", // or any unique key
                        stakeCount: fetchedData.stakeCount,
                        silverCount: fetchedData.stakeCount + " silver",
                        address: fetchedData.address,
                        createdDate: formatDateString(fetchedData.createdDate),
                        lastStakeDate: formatDateString(
                            fetchedData.lastStakeDate
                        ),
                        lastAutoStakeDate: formatDateString(
                            fetchedData.lastAutoStakeDate
                        ),
                    },
                ]);
                console.log("Staked punks retrieved");
            })
            .catch((error) => {
                console.error(
                    "There has been a problem with your fetch operation:",
                    error
                );
            })
            .finally(() => setLoading(false));

        setTimeout(() => {
            setEnableTable(true);
        }, 2000);
    }

    function getNFTAttribute(NFTnumber: any) {
        let selectedImagePath = imageUrl + NFTnumber + ".png";
        setSelectedImage(selectedImagePath);
        // console.log(NFTnumber);
        fetch(metadataUrl + `${NFTnumber}.json`)
            .then((response) => response.json())
            .then((data: any) => {
                console.log(NFTnumber);
                console.log(data.name);
                setAttrName(data.name);
                setAttrDesc(data.description);
                setAttr(data.attributes);
                setSelectedImage(generateUrlFromImageMetadata(data.image));
            });
    }

    function generateUrlFromImageMetadata(metadataImageUrl: string) {
        return imageUrl + metadataImageUrl.split("/").pop();
    }

    function updateAddress(newAddress: string) {
        setImageArray([]);
        setSelectedAddress(newAddress);
        // console.log(selectedAddress);
        getBalance(newAddress);
        // getPunks(newAddress);
    }

    // @ts-ignore
    function quickSwitchArray(imageArray) {
        let newArr = [];
        for (let i = 0; i < imageArray.length; i++) {
            newArr.push(getNFTAttribute(imageArray[i]));
        }

        // console.log(newArr);
        return newArr;
    }

    function getPercentageSold(soldPunks: number, totalPunks: number) {
        let percentageSold = (soldPunks / totalPunks) * 100;

        if (soldPunks > 9994) {
            return parseFloat(percentageSold.toFixed(2));
        } else {
            return parseFloat(percentageSold.toFixed(1));
        }
    }

    let percentageSoldFloat = getPercentageSold(soldPunks, totalPunks);

    // find .ant-progress-text in the DOM and add 'sold' after the existing text
    // @ts-ignore
    let progressText = document.querySelector(".ant-progress-text");
    if (progressText) {
        progressText.innerHTML = `${percentageSoldFloat}% sold`;
    }

    function punkViewerTitleGenerate(imageArray: string | any[]) {
        let message;
        if (imageArray.length === 1) {
            message = `Way to go! You've snagged a Punk for your NFT collection!`;
        } else if (imageArray.length === 2) {
            message = `Congrats! You now own ${imageArray.length} amazing Punks to add to your arsenal!`;
        } else if (imageArray.length >= 3 && imageArray.length < 10) {
            message = `Amazing! You've built up an impressive collection of ${imageArray.length} prized Punks!`;
        } else if (imageArray.length >= 10 && imageArray.length < 20) {
            message = `Congrats! You've amassed a squad of ${imageArray.length} badass Punks!`;
        } else if (imageArray.length >= 20) {
            message = `Nice work! You've gathered an army of ${imageArray.length} unique Punks!`;
        } else {
            message = `You have recruited ${imageArray.length} punks to the resistance!`;
        }
        return message;
    }

    function buttonText() {
        if (isProcessing) {
            return "Processing...";
        } else if (isSuccess) {
            return "Success!";
        } else {
            return "Mint";
        }
    }

    // useEffect cleanup
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <div>
            <div className={"account-list"}>
                <div className="center-div">
                    <div className={"account-item"}>
                        <div className="account-select-container">
                            <p className="account-item__title address">
                                <strong>Select an account: </strong>
                            </p>
                            <select
                                name=""
                                id="wallet-select"
                                onChange={(e) => {
                                    updateAddress(e.target.value);
                                    getPunks(e.target.value);
                                }}
                            >
                                {walletContext.accounts.map((acc) => (
                                    <option
                                        key={acc.address}
                                        value={acc.address}
                                    >
                                        {acc.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <p
                            className="account-item__title address"
                            title={selectedAddress}
                        >
                            <strong>Connected Address: </strong>
                            {selectedAddress &&
                                selectedAddress.substring(0, 15)}
                            ...
                        </p>
                        <p className="account-item__content current-balance">
                            <strong>Your Current Balance is: </strong>
                            {userBalance} $AZERO
                        </p>
                        {/* <p className="account-item__title available-count">
									{percentageSoldFloat}%
								</p> */}
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="Stake" key="1">
                                {!loading && (
                                    <Button
                                        className="sub-wallet-btn sub-wallet-sign-btn view-punks-text"
                                        type={"primary"}
                                        onClick={() => {
                                            postStakeData(selectedAddress);
                                        }}
                                    >
                                        Stake My Punks
                                    </Button>
                                )}

                                {!loading && (
                                    <Button
                                        className="sub-wallet-btn sub-wallet-sign-btn view-punks-text"
                                        type={"primary"}
                                        onClick={() => {
                                            getStakeData(selectedAddress);
                                            getPunks(selectedAddress);
                                        }}
                                    >
                                        View Staked Punks
                                    </Button>
                                )}

                                <Space size="middle">
                                    <Spin
                                        spinning={loading}
                                        indicator={antIcon}
                                        size="large"
                                    />
                                </Space>

                                {enableTable ? (
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="middle"
                                    />
                                ) : null}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
            {imageArray.length > 0 && (
                <div className="nftViewerContainer">
                    <h2 className="white-text punks-count text-center">
                        {punkViewerTitleGenerate(imageArray)}
                    </h2>

                    <div className="nft-image-wrapper" id="nftImageWrapper">
                        {imageArray.map((nftItem, index) => {
                            return (
                                <div key={index} className="nft-image">
                                    <img
                                        loading="lazy"
                                        id={nftItem[0]}
                                        src={nftItem[1]}
                                        alt="Azero Punk"
                                        onClick={() => {
                                            getNFTAttribute(nftItem[0]);
                                            showModal();
                                        }}
                                    />
                                </div>
                            );
                        })}

                        <Modal
                            className="NFTViewerModal"
                            width={1300}
                            title={attrName}
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                        >
                            <div className="modal-preview-container">
                                <img
                                    className="modal-preview"
                                    src={selectedImage}
                                    alt=""
                                />
                                {/* <p className="attrDesc">{attrDesc}</p> */}
                                <p className="white-text mb-0 select-nft-text">
                                    Select another Punk
                                </p>
                                <select
                                    name="myPunks"
                                    id="punk-collection-dropdown"
                                    onChange={(e) =>
                                        getNFTAttribute(e.target.value)
                                    }
                                >
                                    {imageArray.map((nftItem) => {
                                        return (
                                            <option
                                                key={nftItem[0]}
                                                value={nftItem[0]}
                                            >
                                                {nftItem[2]}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="attr-container">
                                {attr.map((attribute, index) => {
                                    return (
                                        <div key={index} className="ant-card">
                                            <div className="ant-card-body">
                                                <div className="ant-statistic">
                                                    <p className="ant-statistic-title">
                                                        {JSON.stringify(
                                                            attribute.trait_type
                                                        ).replace(/['"]+/g, "")}
                                                    </p>
                                                    <p className="ant-statistic-content-value">
                                                        {JSON.stringify(
                                                            attribute.value
                                                        ).replace(/['"]+/g, "")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Modal>
                    </div>
                </div>
            )}
            <div id="endPg"></div>
        </div>
    );
}

export default AccountList;
