import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTelegram,
    faDiscord,
    faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import punksLogo from "../img/punks-logo.png";

interface Props {}

export default function Footer({}: Props): React.ReactElement<Props> {
    return (
        <div className="footer-wrapper">
            <div className="footer">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 footer-wrapper">
                    <p className="col-md-4 mb-0 text-white">
                        © 2023 Azero Punks
                    </p>

                    <span className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                        <a href="https://portal.azeropunks.com">
                            <img
                                className="nav-logo"
                                src={punksLogo}
                                alt="punks logo"
                            />
                        </a>
                    </span>

                    <ul className="nav col-md-4">
                        <li className="nav-item">
                            <a
                                href="https://twitter.com/PunksAzero"
                                className="footer-social-link"
                            >
                                <FontAwesomeIcon
                                    icon={faXTwitter as any}
                                    className="social-link-icon"
                                />
                                <span className="px-2 text-white text-decoration-none">
                                    X (Twitter)
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://t.me/AzeroPunks"
                                className="footer-social-link"
                            >
                                <FontAwesomeIcon
                                    icon={faTelegram as any}
                                    className="social-link-icon"
                                />
                                <span className="px-2 text-white text-decoration-none">
                                    Telegram
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://discord.gg/QZqDxfZQMp"
                                className="footer-social-link"
                            >
                                <FontAwesomeIcon
                                    icon={faDiscord as any}
                                    className="social-link-icon"
                                />
                                <span className="px-2 text-white text-decoration-none">
                                    Discord
                                </span>
                            </a>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    );
}
