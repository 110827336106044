import { AzeroSignerWallet } from "./azero-signer-wallet";
import { PolkadotjsWallet } from './polkadotjs-wallet';
import { SubWallet } from './subwallet';
import { TalismanWallet } from './talisman-wallet';
import { Wallet } from './types';

// Add new wallets here
export const WALLETS: Record<string, string> = {
  'polkadot-js': 'Polkadot{.js}',
  'subwallet-js': 'SubWallet',
  'talisman': 'Talisman',
  'aleph-zero-signer': 'Aleph Zero Signer'
}

const supportedWallets = [
  new PolkadotjsWallet(),
  new SubWallet(),
  new TalismanWallet(),
  new AzeroSignerWallet(),
];

export function getWallets(): Wallet[] {
  return supportedWallets;
}

export function getWalletBySource(
  source: string | unknown
): Wallet | undefined {
  return supportedWallets.find((wallet) => {
    return wallet.extensionName === source;
  });
}

export function isWalletInstalled(source: string | unknown): boolean {
  const wallet = getWalletBySource(source);
  return wallet?.installed as boolean;
}
