import { useEffect, useState } from "react";

import { Button } from "antd";
// import FirstReward from "./FirstReward"; //line 70 and 97
import FooterBootstrap from "../../header-footer/FooterBootstrap";
import Header from "../../header-footer/Header";
import Raffle02 from "./Raffle02";

export default function StoreContainer() {
    const [selectedReward, setSelectedReward] = useState(null);

    useEffect(() => {
        // You can add any side effects related to selectedReward here
        console.log("Selected reward changed:", selectedReward);
    }, [selectedReward]); // Dependency array to watch for changes in selectedReward

    const handleRewardSelection = (reward: any) => {
        setSelectedReward(reward);
    };

    // ======================================================
    // Date Checker Section for temporary reward button

    // HTML for reward button
    const tempReward = (
        <div className="reward-selection">
            <Button
                className="ant-btn ant-btn-primary sub-wallet-btn sub-wallet-btn-small-size store-btn"
                onClick={() => handleRewardSelection("raffle02")}
            >
                Second Raffle Ticket
            </Button>
        </div>
    );

    // actual date checker
    function dateCheckerHelper(
        targetDate: Date,
        checkedContent: JSX.Element
    ): JSX.Element | null {
        const currentDate = new Date();
        if (currentDate > targetDate) {
            return null;
        } else {
            return checkedContent;
        }
    }

    // calling the dateCherHelper
    const tempRewardView = dateCheckerHelper(
        new Date("2024-05-13"),
        tempReward
    );

    return (
        <>
            <Header />
            <div className="store">
                <div className="store-wrapper">
                    <div className="store-title">
                        <div className="store-title-wrapper">
                            {/* <h2>Redeem rewards for Silver!</h2> */}
                            <h2>No reward offers righ now.</h2>
                            <h3>Follow social media for updates</h3>
                        </div>
                    </div>

                    <div className="store-wrapper">
                        <div className="select-reward">
                            <div className="select-reward-wraper">
                                {/* Merch store temporarily unavailable */}
                                {/* <div className="reward-selection">
                                    <Button
                                        className="ant-btn ant-btn-primary sub-wallet-btn sub-wallet-btn-small-size store-btn"
                                        onClick={() =>
                                            handleRewardSelection("first")
                                        }
                                    >
                                        Merch Discount Code
                                    </Button>
                                </div> */}
                                {/* <div className="reward-selection">
                                    <Button
                                        className="ant-btn ant-btn-primary sub-wallet-btn sub-wallet-btn-small-size store-btn"
                                        onClick={() =>
                                            handleRewardSelection("second")
                                        }
                                    >
                                        Raffle Ticket (1 Entry)
                                    </Button>
                                </div> */}
                                {/* {tempRewardView} */}
                            </div>
                        </div>
                    </div>

                    <div className="selected-reward-wrapper">
                        {/* Conditionally render FirstReward or SecondReward based on selectedReward */}
                        {/* {selectedReward === "first" && <FirstReward />} */}
                        {selectedReward === "raffle02" && <Raffle02 />}
                    </div>
                </div>
                <FooterBootstrap />
            </div>
        </>
    );
}
