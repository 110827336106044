import { NavLink } from "react-router-dom";
import React from "react";
import punksLogo from "../img/punks-logo.png";

export default function Header() {
    return (
        <div className="store-header">
            <div className="store-header-wrapper">
                <div className="store-header-left">
                    <div className="header-logo-wrapper">
                        <div className="header-logo">
                            <a
                                href="https://portal.azeropunks.com/"
                                className="link"
                            >
                                <img
                                    className="nav-logo"
                                    src={punksLogo}
                                    alt="punks logo"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="store-header-right">
                    <div className="store-nav-menu-wrapper">
                        <ul className="store-nav-menu">
                            <NavLink to="/home">
                                <li className="store-nav-menu-item">Home</li>
                            </NavLink>
                            |
                            <NavLink to="/thelegend">
                                <li className="store-nav-menu-item">
                                    The Legend
                                </li>
                            </NavLink>
                            |
                            <a href="http://a0.artzero.io/collection/5E7HFbvv7o4zFpJxnUsJF5CNktew1T6nh3gctbkSTWGAeiMT?is_for_sale=true">
                                <li className="store-nav-menu-item">
                                    Marketplace
                                </li>
                            </a>
                            |
                            <NavLink to="/welcome">
                                <li className="store-nav-menu-item">
                                    My Account
                                </li>
                            </NavLink>
                            |
                            <NavLink to="/store">
                                <li className="store-nav-menu-item">
                                    Gift Shop
                                </li>
                            </NavLink>
                            |
                            <a
                                href={require("../resources/WP2.0.pdf")}
                                className="link"
                            >
                                <li className="store-nav-menu-item">
                                    Whitepaper
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
