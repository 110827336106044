import { ApiPromise } from "@polkadot/api";
import { Fragment, useContext, useEffect, useState } from "react";
import { OpenSelectWallet, WalletContext } from "../../../../contexts";
import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import productImage from "../../img/raffle_1.png";
import { useNavigate } from "react-router-dom";

const urlBase = "https://azeropunks-staking.azurewebsites.net";
// zzz
function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function Raffle02() {
    const [loading, setLoading] = useState(false);
    const [api, setApi] = useState<ApiPromise>();
    const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
    const navigate = useNavigate();
    const [selectedAccount, setSelectedAccount] =
        useState<InjectedAccountWithMeta>();

    const selectWallet = useContext(OpenSelectWallet);
    const walletContext = useContext(WalletContext);
    const [selectedAddress, setSelectedAddress] = useState<string>("");

    const [raffleCodes, setRaffleCodes] = useState([0]);

    const setup = async () => {
        apiProductInfo();
    };

    const productCodeValue = "raffle02"; // productCode for the product from the shopify store
    // const productImage =
    //     "https://azeropunks.myshopify.com/cdn/shop/files/440A0D83-49C8-4073-A8DF-BD899640A3C6.jpg?v=1702249785&width=493"; // link to reward image at shopify
    const rewardPriceConst = 9;
    // ================= START OF TESTING =================

    useEffect(() => {
        setup();
    }, []);

    useEffect(() => {
        if (!api) return;

        (async () => {
            const time = await api.query.timestamp.new();

            console.log(time.toHuman());
            // console.log(time.toPrimitive()); // Polkadot uses toPrimitive
        })();
    }, [api]);

    //Get Balance
    // const getAzeroBalance = async (selectedAddress: string) => {
    //     const wsProvider = new WsProvider(wsUrl);
    //     const api = await ApiPromise.create({
    //         provider: wsProvider,
    //         noInitWarn: true,
    //     });

    //     let account = await api.query.system.account(selectedAddress);

    //     // @TODSO: Fix this dirty stuff for 'properly' showing the AZERO balance value
    //     const trillion = BigInt(1000000000000);

    //     const azeroUserBalanceString = (
    //         account.data.free.toBigInt() / trillion
    //     ).toString();

    //     // @ts-ignore
    //     setAzeroUserBalance(Number(azeroUserBalanceString));
    //     //     // api.rpc.chain.subscribeNewHeads((header) => {
    //     //     //   console.log(`Chain is at #${header.number}`);
    //     //     // });
    // };

    async function updateAddress(newAddress: string) {
        await setSelectedAddress(newAddress);
        // await getAzeroBalance(newAddress);
        await apiUserBalance(newAddress);
        await apiProductView(newAddress);
    }

    useEffect(() => {
        let isMounted = true;
        const init = async () => {
            const defaultAddress =
                walletContext &&
                walletContext.accounts &&
                walletContext.accounts[0] &&
                walletContext.accounts[0].address;

            if (isMounted) {
                await updateAddress(defaultAddress);
            }
        };

        init().catch(console.error);

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletContext]);

    interface productInfoData {
        prodCode?: string;
        name?: string;
        price?: number;
        maxBuy?: number;
        available?: number;
    }

    const [dataApiInfo, setDataApiInfo] = useState<productInfoData>();
    // const [dataApiInfo, setDataApiInfo] = useState<productInfoData>({ //for testing
    //     prodCode: "punkreward",
    //     name: "Azero Punks mini reward pack",
    //     price: 150,
    //     maxBuy: 1,
    //     available: 100,
    // });

    interface productViewData {
        productCode: string;
        product: {
            prodCode: string;
            name: string;
            price: number;
            maxBuy: number;
            available: number;
        };
        code: string;
        usedBy: string;
        usedDate: string;
    }

    const [dataApiView, setDataApiView] = useState<productViewData>();

    interface userBalanceData {
        address?: string;
        stakeCount?: number;
    }

    const [userBalance, setUserBalance] = useState<userBalanceData>();
    const [azeroUserBalance, setAzeroUserBalance] = useState(0);

    interface buyNewCodeData {
        type?: any;
        title?: any;
        status?: number;
        errors?: any;
        detail?: string;
        traceId?: string;
        prodCode?: any;
        product?: any;
        code?: any;
        details?: string;
        usedBy?: any;
        usedDate?: any;
    }

    const [newCodeData, setNewCodeData] = useState<buyNewCodeData>();
    // const [newCodeData, setNewCodeData] = useState<buyNewCodeData>({ //for testing
    //     prodCode: "punkreward",
    //     product: "product",
    //     code: "CODE-CODE-CODE",
    //     usedBy: "today",
    //     usedDate: "usedDate",
    // });

    const balance = userBalance?.stakeCount || 0; //fetch from apiUserData
    const balanceDisplay = `${balance} silver`;

    const userCodesDisplay = (userCodes: any) => {
        const codeElements = userCodes.map((code: string, index: number) => (
            <Fragment key={index}>
                {code}
                <br />
            </Fragment>
        ));

        return <>{codeElements}</>;
    };

    const apiProductView = (
        address: any = selectedAddress,
        prodCode: string = productCodeValue
    ) => {
        const urlView = `${urlBase}/api/Product/view?prodCode=${prodCode}&address=${address}`;

        fetch(urlView, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Host: "azeropunks-staking.azurewebsites.net",
            },
        })
            .then((response) => {
                console.log("response:", response);

                if (response.status === 204) {
                    console.log(
                        "apiProductView Response - no content",
                        response
                    );

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log("!response.ok", response);
                    console.log(
                        "apiProductView Response Status:",
                        response.status
                    );
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((fetchedData: any[]) => {
                if (fetchedData.length > 0) {
                    console.log(fetchedData);
                    const firstItem = fetchedData[0]; // Access the first element of the array
                    setDataApiView({
                        productCode: firstItem.productCode,
                        product: {
                            prodCode: firstItem.product.prodCode,
                            name: firstItem.product.name,
                            price: firstItem.product.price,
                            maxBuy: firstItem.product.maxBuy,
                            available: firstItem.product.available,
                        },
                        code: firstItem.code,
                        usedBy: firstItem.usedBy,
                        usedDate: firstItem.usedDate,
                    });

                    let raffleCodeArray = [];
                    for (let i = 0; i < fetchedData.length; i++) {
                        raffleCodeArray.push(fetchedData[i].code);
                    }

                    setRaffleCodes(raffleCodeArray);
                    console.log("apiProductView: RaffleCodes ", raffleCodes);
                } else {
                    console.log("No data received from the API");
                    setDataApiView(getDummyProductData());
                    setRaffleCodes([]);
                }
            })
            .catch((error) => {
                console.log("apiProductView CATCHed an error", error);
            });
    };

    const getDummyProductData = () => {
        return {
            productCode: productCodeValue,
            product: {
                prodCode: productCodeValue,
                name: "The Raffle ticket test!",
                price: 10,
                maxBuy: 5,
                available: 400,
            },
            code: "",
            usedBy: "",
            usedDate: "",
        };
    };

    // TO DO
    // apiUserData

    const apiUserBalance = async (userAddress: any) => {
        setLoading(true);

        const urlUserData = `${urlBase}/Staking/balance?address=${userAddress}`;
        // const urlUserData = `https://azeropunks-staking.azurewebsites.net/Staking/balance?address=5DLq5iqTyPCn6WJMTB97aJYNXFCWGEqTevPaE5Ts1iv2uJaL`;

        fetch(urlUserData, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Host: "azeropunks-staking.azurewebsites.net",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    console.log(
                        "apiUserData didn't return data",
                        response.status
                    );
                    return "{}";
                }

                if (!response.ok) {
                    console.log(
                        "Something went wrong. Status:",
                        response.status
                    );
                    throw new Error("Network response was not ok");
                }
                // Handle unexpected end of JSON input
                try {
                    let JSONData = response.json();
                    return JSONData;
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    throw new Error("Failed to parse JSON response");
                }
            })
            .then((fetchedData: any) => {
                if (Object.keys(fetchedData).length > 0) {
                    console.log(fetchedData);
                    setUserBalance({
                        address: fetchedData.address,
                        stakeCount: fetchedData.stakeCount,
                    });
                } else {
                    console.log("apiUserBalance failed Fetch (no data)");
                    setUserBalance({
                        address: userAddress,
                        stakeCount: 0,
                    });
                    return null;
                }
            })
            .catch((error) => {
                console.log("apiUserBalance error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // apiProductInfoData
    // const apiProductInfo = (selectedAddress: string) => {
    const apiProductInfo = () => {
        const urlInfo = `${urlBase}/api/Product/info?prodCode=${productCodeValue}`;

        fetch(urlInfo, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    console.log("Response status 204");

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log("network response was not ok");
                    console.log("Response Status:", response.status);
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((fetchedData: any) => {
                setDataApiInfo({
                    prodCode: fetchedData.prodCode,
                    name: fetchedData.name,
                    price: fetchedData.price,

                    maxBuy: fetchedData.maxBuy,
                    available: fetchedData.available,
                });
                console.log("fetched productInfoData");
            })
            .catch((error) => {
                console.log("apiProductInfo CATCHed an error", error);
            });
    };

    // end fetch

    const buyDiscountCodeWithSilver = async (address: any, prodCode: any) => {
        const buyUrl = `${urlBase}/api/Product/buy?prodCode=${prodCode}&address=${address}`;

        fetch(buyUrl, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    console.log(
                        "BuyDiscountCodeWithSilver response status 204",
                        response.status
                    );

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log(
                        "BuyDiscountCodeWithSilver Response Status:",
                        response.status
                    );
                    throw new Error(
                        "BuyDiscountCodeWithSilver Network response was not ok"
                    );
                }
                return response.json();
            })
            .then((fetchedData: any) => {
                setNewCodeData({
                    prodCode: fetchedData.prodCode,
                    product: fetchedData.product,
                    code: fetchedData.code,
                    usedBy: fetchedData.usedBy,
                    usedDate: fetchedData.usedDate,
                });
                console.log("fetched buyDiscountCodeWithSilver");
            })
            .catch((error) => {
                console.log(
                    "buyDiscountCodeWithSilver CATCHed an error",
                    error
                );
            });
    };

    const handleBuyCode = async () => {
        await buyDiscountCodeWithSilver(selectedAddress, dataApiInfo?.prodCode);
        await sleep(1000);
        await apiProductView(selectedAddress);
    };

    console.log("raffle Tickets: ", raffleCodes);

    const spellingTicket = (ticketAmount: any) => {
        switch (ticketAmount) {
            case 0:
                return "tickets.";
            case 1:
                return "ticket:";
            default:
                return "tickets:";
        }
    };

    return (
        <div className="store-content">
            <div className="store-content-wrapper">
                <div className="store-item">
                    <div className="store-item-wrapper">
                        <div className="item-img-wrapper">
                            <img
                                src={productImage}
                                alt="photo of reward"
                                className="img"
                            />
                        </div>
                        <div className="item-name-wrapper">
                            <p className="item-name">
                                Azero Punks second raffle ticket!
                            </p>
                        </div>
                        <div className="price-wrapper item-details details">
                            <p className="price-question">Price: </p>
                            <p className="price-actual">
                                {rewardPriceConst + 1} silver
                            </p>
                        </div>
                        <div className="available-wrapper item-details details">
                            <p className="available-question">
                                Currently available:
                            </p>
                            <p className="available-actual">5 per user</p>
                        </div>

                        {!selectedAddress || selectedAddress.trim() === null ? (
                            navigate("/welcome")
                        ) : (
                            <div className={"account-list"}>
                                <div className="center-div">
                                    <div className={"account-item"}>
                                        <div className="account-select-container">
                                            <p className="account-item__title address">
                                                <strong>
                                                    Select an account:{" "}
                                                </strong>
                                            </p>
                                            <select
                                                name=""
                                                id="wallet-select"
                                                onChange={(e) => {
                                                    updateAddress(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {walletContext.accounts.map(
                                                    (acc) => (
                                                        <option
                                                            key={acc.address}
                                                            value={acc.address}
                                                        >
                                                            {acc.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <p
                                            className="account-item__title address"
                                            title={selectedAddress}
                                        >
                                            <strong>Connected Address: </strong>
                                            {selectedAddress &&
                                                selectedAddress.substring(
                                                    0,
                                                    15
                                                )}
                                            ...
                                        </p>
                                        {/* <p className="account-item__content current-balance">
                                                        <strong>
                                                            Your Current AZERO
                                                            Balance is:{" "}
                                                        </strong>
                                                        {azeroUserBalance}{" "}
                                                        $AZERO
                                                    </p> */}

                                        <p className="account-item__content current-balance">
                                            <strong>
                                                Your Current Silver Balance is:{" "}
                                            </strong>
                                            {balance} Silver
                                        </p>

                                        <p className="account-item__content current-balance">
                                            {raffleCodes ? (
                                                <>
                                                    You have{" "}
                                                    <strong>
                                                        {raffleCodes.length}
                                                    </strong>{" "}
                                                    raffle{" "}
                                                    {spellingTicket(
                                                        raffleCodes.length
                                                    )}
                                                    <br />
                                                    {userCodesDisplay(
                                                        raffleCodes
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <br />
                                            {raffleCodes.length < 5 ? (
                                                balance > rewardPriceConst ? (
                                                    <strong>
                                                        Get raffle entry below !
                                                    </strong>
                                                ) : (
                                                    <strong>
                                                        Insufficient silver.
                                                        Come back later !
                                                    </strong>
                                                )
                                            ) : (
                                                <>
                                                    Maximum number of tickets
                                                    bought
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* buy code */}
                        <div className="buy-code-wrapper">
                            {newCodeData?.detail ? (
                                <>
                                    <div className="details">
                                        <h5>Not enough Silver</h5>
                                    </div>
                                    {balance !== 0 ? (
                                        <div className="details">
                                            <p>You have {balanceDisplay}</p>
                                        </div>
                                    ) : null}
                                </>
                            ) : // userCodes == "" &&
                            balance > 9 && raffleCodes.length < 5 ? (
                                <div className="buy-code-btn">
                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                        onClick={handleBuyCode}
                                    >
                                        Buy raffle ticket with Silver
                                    </button>
                                </div>
                            ) : (
                                <></>
                            )}

                            <br />
                            <p className="buy-code-info info">
                                Limited to 5 entries per user.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
