import React from "react";

import Footer from "../../header-footer/Footer";
import Header from "../../header-footer/Header";

import chapter1_thumb from "./episodes/thumbnails/chapter1_thumb.png";
import { NavLink } from "react-router-dom";

interface Props {}

export default function TheLegendContainer(): React.ReactElement<Props> {
    return (
        <>
            <div className="the-legend-container">
                <div className="the-legend-container-wrapper">
                    <Header />

                    <div className="episodes-contents-navigation">
                        <h4 className="episodes-contents-navigation-title">
                            Chapter 1
                        </h4>
                        <ul className="episodes-contents-navigation-list">
                            <NavLink to="/episode">
                                <li className="episodes-contents-item">
                                    <div className="episode-picture">
                                        <img
                                            src={chapter1_thumb}
                                            alt="chapter 1"
                                        />
                                    </div>
                                </li>
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
