import "./Welcome.scss";

import { OpenSelectWallet, WalletContext } from "../contexts";
import React, { useContext, useEffect } from "react";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "./main-site/header-footer/Header";

interface Props {}

function Welcome({}: Props): React.ReactElement<Props> {
    const selectWallet = useContext(OpenSelectWallet);
    const walletContext = useContext(WalletContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (walletContext.wallet && walletContext.accounts.length > 0) {
            navigate("/wallet-info");
        }
    }, [walletContext.wallet]);

    return (
        <>
            <Header />
            <div className={"welcome-wrapper"}>
                <div className={"welcome-content"}>
                    {/* <img src="/azeropunks-logo.png" alt="" width="150px" /> */}
                    <Button
                        className="ant-btn ant-btn-primary sub-wallet-btn sub-wallet-btn-small-size"
                        onClick={selectWallet.open}
                    >
                        Connect Wallet
                    </Button>
                    {/* <p>Supported browsers: Chrome, Brave, Firefox</p> */}
                </div>
            </div>
        </>
    );
}

export default Welcome;
