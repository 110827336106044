import { Fragment, useContext, useEffect, useState } from "react";
import { OpenSelectWallet, WalletContext } from "../../../../../contexts";
import LogoWhite from "../../../img/TheLegend/Logo_white_transparent_still.png";
import TheLegendEp1 from "./TheLegendEp1";

export default function EpisodeContainer(): React.ReactElement {
    const walletContext = useContext(WalletContext);
    const [selectedAddress, setSelectedAddress] = useState<string>("");

    const [videoLink, setVideoLink] = useState<string | null>(null);
    const selectWallet = useContext(OpenSelectWallet);
    async function updateAddress(newAddress: string) {
        await setSelectedAddress(newAddress);
    }

    useEffect(() => {
        if (walletContext && walletContext.accounts.length > 0) {
            setSelectedAddress(walletContext.accounts[0].address);
        }
    }, [walletContext]);

    useEffect(() => {
        let isMounted = true;
        const init = async () => {
            const defaultAddress =
                walletContext &&
                walletContext.accounts &&
                walletContext.accounts[0] &&
                walletContext.accounts[0].address;

            if (isMounted) {
                await updateAddress(defaultAddress);
            }
        };

        init().catch(console.error);

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletContext]);

    const generateVideoLink = async (address: string) => {
        try {
            const response = await fetch(
                `https://images.azeropunks.com/comic/generateTokenForAddress?address=${address}`
            );

            if (response.status === 204) {
                console.log("No content");
                return null;
            }

            if (!response.ok) {
                console.error("Network response was not ok", response.status);
                throw new Error("Network response was not ok");
            }

            const fetchedData = await response.json();
            console.log("fetchedData is: ", fetchedData);

            return `https://images.azeropunks.com/comic/video?token=${fetchedData.token}`;
        } catch (error) {
            console.error("Error generating video link:", error);
            return null;
        }
    };

    useEffect(() => {
        const fetchVideoLink = async () => {
            if (selectedAddress) {
                const link = await generateVideoLink(selectedAddress);
                setVideoLink(link);
            }
        };

        fetchVideoLink();
    }, [selectedAddress]);

    return (
        <div className="episode-container">
            <div className="episode-container-wrapper">
                <div className="episode-header">
                    <div className="episode-header-wrapper">
                        <div className="header-logo header-left">
                            <div className="header-logo-wrapper">
                                <a href="https://portal.azeropunks.com/">
                                    <img
                                        src={LogoWhite}
                                        alt="logo"
                                        className="logo"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="header-music-icon header-right"></div>
                    </div>
                </div>
                <div className="episode-content">
                    {videoLink ? (
                        <TheLegendEp1 videosrc={videoLink} />
                    ) : (
                        <h3>
                            You have to have at least one punk NFT and your
                            wallet needs to be connected
                        </h3>
                    )}
                </div>
            </div>
            <div className="episode-footer">
                <div className="episode-footer-wrapper">
                    <div className="left-side previous-page">PREVIOUS PAGE</div>
                    <div className="right-side next-page">NEXT PAGE</div>
                </div>
            </div>
        </div>
    );
}
