import React, { createRef } from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";
// import { faPause } from "@fortawesome/free-solid-svg-icons";

interface Props {
    videosrc?: string;
}

export default function TheLegendEp1({
    videosrc,
}: Props): React.ReactElement<Props> {
    // const videoContainer = document.querySelector(".video-wrapper");
    const videoContainer = createRef<HTMLDivElement>();

    const videoPlayer = createRef<HTMLVideoElement>();

    function togglePlay() {
        // videoPlayer.current!.play();

        videoPlayer.current?.paused
            ? videoPlayer.current.play()
            : videoPlayer.current!.pause();
    }

    function removePauseClass() {
        videoContainer.current?.classList.remove("paused");
    }
    function addPauseClass() {
        videoContainer.current?.classList.add("paused");
    }

    return (
        <div className="episode">
            <div className="episode-wrapper">
                <h3 className="episode-title">Chapter 1</h3>
                <div className="video-wrapper" ref={videoContainer}>
                    <div className="video-controls-container">
                        <div className="empty-helper"></div>
                        {/* <div className="controls">
                            <button
                                className="play-pause-btn"
                                onClick={togglePlay}
                            >
                                <FontAwesomeIcon
                                    icon={faPlay}
                                    className="play-btn"
                                />
                                <FontAwesomeIcon
                                    icon={faPause}
                                    className="pause-btn"
                                />
                            </button>
                        </div> */}
                        <div className="timelaine-container"></div>
                    </div>
                    <video
                        controls
                        ref={videoPlayer}
                        className="video"
                        src={videosrc}
                        onPlay={removePauseClass}
                        onPause={addPauseClass}
                    ></video>
                </div>
            </div>
        </div>
    );
}
