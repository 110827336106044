import React, { useContext } from "react";
import AccountList from "../components/AccountList";
import { WalletContext } from "../contexts";
import "./WalletInfo.scss";
import Header from "../components/main-site/header-footer/Header";

interface Props {
    className?: string;
}

function WalletInfo({}: Props): React.ReactElement<Props> {
    const walletContext = useContext(WalletContext);

    return (
        <>
            <Header />
            <div className={"boxed-container"}>
                <div className={"wallet-info-page"}>
                    {/* <div className='wallet-info-page__text'>Mint AzeroPunks</div>
      <div className='wallet-info-page__text'>Account List</div> */}
                    <AccountList />
                </div>
            </div>
        </>
    );
}

export default WalletInfo;
